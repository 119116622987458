.animate-border {
  animation: pulse-animation 2s linear infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: none;
  }
  50% {
    box-shadow: 0px 0px 9px 9px rgba(112, 224, 0, 0.75);
    -webkit-box-shadow: 0px 0px 9px 9px rgba(112, 224, 0, 0.75);
    -moz-box-shadow: 0px 0px 9px 9px rgba(112, 224, 0, 0.75);
  }
  100% {
    border-color: transparent;
  }
}
.tooltip-content {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  transition: opacity 0.5s ease;
  opacity: 1;
}

.tooltip-content.fade-out {
  opacity: 0;
}

