.main-icon-container {
  background-color: #002f49b7 
}

.icon-container {
  outline: none;
  border: none;
  background-color: transparent;
}

.icon-container .icon {
  color: #cad2c5;
  transition: transform 0.3s;  
  border-radius: 100%;
  overflow: hidden;
  background-color: transparent;
}

.icon-container .icon:hover {
  transform: rotate(360deg);
  transition-delay: 1s;
  color: #adff2f;
}

