.link-container {
  background-color: #1b433262;
  width: 90%;
  max-width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  border: none;
  outline: none;
  z-index: 100;
  color: white;
  backdrop-filter: blur(100px);
  position: relative;
}

.link-container:hover {
  background-color: #a8dadc;
  color: #1d3557;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  -webkit-btn-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  -moz-btn-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  text-decoration: underline;
  text-decoration-thickness: 1px;
  transform: scale3d(1.05, 1.05, 1);  
  will-change: transform;
}
