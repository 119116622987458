.modal-container {
  background-color: #8ecae6;
  width: 90%;
  max-width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  z-index: 100;      
  position: relative;
  color: black;
}
