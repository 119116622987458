* {
  box-sizing: border-box;
}

.spin-player {
  animation: spin 4s infinite linear;
}

.spin-stop {
  animation: spin-stop 3s ease-out;
  animation-iteration-count: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-stop {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

/*  */

@keyframes music-note-animation {
  0% {
    transform: translateX(0);
    opacity: 0;
  }

  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }

  100% {
    transform: translateX(120%) translateY(-60%);
    opacity: 0;
  }
}
.music-note {
  position: absolute;
  top: 2%;
  left: 1%;
  animation: music-note-animation 2s infinite ease-in-out;  
  opacity: 0;


  z-index: 40;
}

.music-note-1 {
  animation-delay: 0.6s;
}

.music-note-2 {
  animation-delay: 0.8s;
}

.music-note-3 {
  animation-delay: 0.10s;
}

