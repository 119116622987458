.animation {
  animation: text-animation 15s infinite ease-in-out;
  animation-delay: 10s;
  transform: translateY(0%);
}

@keyframes text-animation {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  12% {
    transform: translateY(0%);
  }
  24% {
    transform: translateY(26%);
  }
  36% {
    transform: translateY(26%);
  }
  48% {
    transform: translateY(52%);
  }
  60% {
    transform: translateY(52%);
  }
  72% {
    transform: translateY(78%);
  }
  84% {
    transform: translateY(78%);
  }
  96% {
    transform: translateY(78%);
  }
  100% {
    transform: translateY(0%);
  }
}
