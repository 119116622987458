.snowflake {
  text-shadow: 0 0 1px #000;
}

@-webkit-keyframes snowflakes-fall {
  0% {
    top: -10%;
  }
  100% {
    top: 100%;
  }
}
@keyframes snowflakes-fall {
  0% {
    top: -10%;
  }
  100% {
    top: 100%;
  }
}

.snowflake {
  position: fixed;
  top: -10%;
  z-index: 9999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: snowflakes-fall;
  -webkit-animation-duration: 50s, 3s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-play-state: running, running;
  animation-name: snowflakes-fall;
  animation-duration: 10s, 10s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running;
  pointer-events: none;
}

.snowflake:nth-of-type(0) {
  left: 3%;
  -webkit-animation-delay: 0s, 0s;
  animation-delay: 0s, 0s;
}
.snowflake:nth-of-type(1) {
  left: 1%;
  -webkit-animation-delay: 1.5s, 1.5s;
  animation-delay: 1.5s, 1.5s;  
}
.snowflake:nth-of-type(2) {
  left: 5%;
  -webkit-animation-delay: 7s, 0.7s;
  animation-delay: 7s, 0.7s;
}
.snowflake:nth-of-type(3) {
  left: 20%;
  -webkit-animation-delay: 10.2s, 10.2s;
  animation-delay: 8.2s, 8.2s;
}
.snowflake:nth-of-type(4) {
  left: 49%;
  -webkit-animation-delay: 2s, 2.4s;
  animation-delay: 2s, 2s;
}
.snowflake:nth-of-type(5) {
  left: 18%;
  -webkit-animation-delay: 10s, 3.3s;
  animation-delay: 10s, 3.3s;
}
.snowflake:nth-of-type(6) {
  left: 7%;
  -webkit-animation-delay: 5.5s, 1.5s;
  animation-delay: 5.5s, 1.5s;
}
.snowflake:nth-of-type(7) {
  left: 66%;
  -webkit-animation-delay: 3s, 1.8s;
  animation-delay: 3s, 1.8s;
}
.snowflake:nth-of-type(8) {
  left: 85%;
  -webkit-animation-delay: 1.8s, 0.5s;
  animation-delay: 1.8s, 0.5s;
}
.snowflake:nth-of-type(9) {
  left: 10%;
  -webkit-animation-delay: 3.7s, 1.9s;
  animation-delay: 3.7s, 1.9s;
}

.snowflake:nth-of-type(10) {
  left: 56%;
  -webkit-animation-delay: 0.5s, 0.5s;
  animation-delay: 0.5s, 0.5s;
}
.snowflake:nth-of-type(11) {
  left: 45%;
  -webkit-animation-delay: 6s, 6s;
  animation-delay: 6s, 6s;
}
.snowflake:nth-of-type(12) {
  left: 42%;
  -webkit-animation-delay: 8s, 0.8s;
  animation-delay: 8s, 0.8s;
}
.snowflake:nth-of-type(13) {
  left: 53%;
  -webkit-animation-delay: 5s, 2.7s;
  animation-delay: 5s, 2.7s;
}
.snowflake:nth-of-type(14) {
  left: 92%;
  -webkit-animation-delay: 3.2s, 2.9s;
  animation-delay: 3.2s, 2.9s;
}
.snowflake:nth-of-type(15) {
  left: 80%;
  -webkit-animation-delay: 10s, 4s;
  animation-delay: 10s, 4s;
}
.snowflake:nth-of-type(16) {
  left: 69%;
  -webkit-animation-delay: 7s, 2.3s;
  animation-delay: 7s, 2.3s;
}
.snowflake:nth-of-type(17) {
  left: 70%;
  -webkit-animation-delay: 4s, 1.5s;
  animation-delay: 4s, 1.5s;
}
.snowflake:nth-of-type(18) {
  left: 24%;
  -webkit-animation-delay: 3.2s, 1s;
  animation-delay: 2.2s, 1s;
}
.snowflake:nth-of-type(19) {
  left: 87%;
  -webkit-animation-delay: 4.5s, 2.2s;
  animation-delay: 4.5s, 2.2s;
}
.snowflake:nth-of-type(20) {
  left: 94%;
  -webkit-animation-delay: 6s, 3s;
  animation-delay: 6s, 3s;
}
